<template>
  <div class="groupTab__container" ref="link">
    <router-link
      :to="{
        path: `/group/${this.$route.params.id}/tab/${this.tab.id}`,
      }"
    >
      <span v-if="!itemInEditing" class="tabName">{{ tab.name }}</span>
      <input
        v-else
        type="text"
        :ref="'tabName' + tab.id"
        :value="tab.name"
        :disabled="!itemInEditing"
        @keyup.enter="makeChanges($event, tab.id)"
      />
      <div
        class="post-controls"
        v-if="tab.name !== 'Main' && (creator || userStore.user.isAdmin || moderator)"
      >
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <div
              class="editIcon1"
              :class="{ editIconOpen: itemInEditing }"
            ></div>
          </template>
          <b-dropdown-item @click="showEditTab(tab.id)">Edit</b-dropdown-item>
          <b-dropdown-item @click="deleteTab(tab.id)">Remove</b-dropdown-item>
        </b-dropdown>
      </div>
    </router-link>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import { BDropdown, BDropdownItem } from "bootstrap-vue"

export default {
  name: "GroupTab",
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    tab: Object,
    moderator: Boolean,
    creator: Boolean,
    numOfTabs: Number,
  },
  data() {
    return {
      itemInEditing: false,
    }
  },
  methods: {
    showEditTab(tabId) {
      this.itemInEditing = true
      this.$nextTick(() => this.$refs[`tabName${tabId}`].focus())
    },
    deleteTab(tabId) {
      let ok = confirm(
        "Are you sure you want to delete this tab and all posts related to this tab?"
      )
      if (ok) {
        this.$api.deleteTab(tabId, this.userStore.accessToken)
        this.$emit("deleteTab", tabId)
      }
    },
    async makeChanges(e, tabId) {
      const inpVal = e.target.value
      const tab = {
        tabId: tabId,
        tabName: inpVal,
      }
      const response = await this.$api.editTab(tab, this.userStore.accessToken)
      if (response.success) {
        this.$emit("tabUpdated", tab)
      }
      this.itemInEditing = false
    },
    getElementWidth() {
      this.$emit("getWidth", this.$refs.link.offsetWidth)
    },
  },
  mounted() {
    this.tabRoute = this.$router.currentRoute.path
    this.getElementWidth()
  },
  computed: {
    ...mapStores(useUserStore),
  },
}
</script>

<style lang="scss" scoped>
.groupTab__container {
  display: flex;
  white-space: nowrap;

  .tabName {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  input[type="text"] {
    box-sizing: border-box;
    width: 100%;
    max-width: 100px;
    border: 0;
    background-color: var(--dark);
    color: #fff;
    cursor: pointer;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
    height: 45px;
    width: auto;
    min-width: 150px;
    background-color: var(--dark);
    border-radius: 15px 15px 0 0;
    padding-left: 10px;
  }

  a.router-link-active {
    background-color: #fff;
    color: var(--dark);
  }
  a.router-link-active input[type="text"] {
    border: 0;
    background-color: #fff;
    color: var(--dark);
  }

  a.router-link-active .editIcon1 {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background-image: url("../../assets/icons/edit.svg");
    background-size: cover;
  }

  .editIcon1 {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background-image: url("../../assets/icons/editWhite.svg");
    background-size: cover;
    position: relative;
    cursor: pointer;
  }

  .editIconOpen::after {
    content: "";
    position: absolute;
    top: 10px;
    height: 20px;
    width: auto;
    background-color: var(--dark);
  }

  .post-controls {
    .b-dropdown::v-deep {
      button {
        background-color: transparent;
        border: 0;
      }
      .dropdown-menu {
        position: absolute;
        display: none;
        z-index: 10;
        background: var(--dark);
        padding: 8px 0;
        width: auto;
        text-align: right;
        box-shadow: 3px 3px 10px var(--background-dark);

        &.show {
          display: initial;
        }
        li {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;

          &:hover {
            background: linear-gradient(120deg, #fd8e00 0%, #fd6631 100%);
          }

          a {
            text-decoration: none;
            color: #fff;
            margin-right: 10px;
            padding: 5px 0;
            width: 100px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
