<template>
    <div class="expandable" :class="{'expand':expand}">
        <h3 @click="expand = !expand">Event List</h3>
        <b-icon-chevron-down @click="expand = !expand" v-bind:class="{'expand':expand}"></b-icon-chevron-down>
        <div class="events-list" v-if="groupEvents.length > 0">
            <div class="event" v-for="event in groupEvents" :key="event.id" @click.self="openEventDetails(event.id)">
                <img @click.self="openEventDetails(event.id)" src="@/assets/icons/calendar.svg" >
                <h4 @click.self="openEventDetails(event.id)">{{ event.title }}</h4>
				<h5 @click.self="openEventDetails(event.id)">{{ $formatter.formatDateTime(event.date_when, true) }}</h5>
                <div class="icon-wrapper" v-if="groupCreator || userStore.isAdmin">
                    <img @click.self="openEditForm(event)" class="event-edit-icon" src="/img/editWhite.c4d3c6d7.svg" alt="edit-icon">
                </div>
            </div>
        </div>
        <h4 v-else>There is no events for this group.</h4>
    </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Component
import { BIconChevronDown } from "bootstrap-vue";
export default {
    name: 'GroupArchive',
    components: { BIconChevronDown },
    props: {
        groupId: Number,
        groupCreator: Boolean
    },
    data() {
        return {
            expand: false,
            groupEvents: []
        }
    },
    async mounted(){
        this.getGroupEvents();
    },
    methods: {
        openEditForm(event) {
            this.$router.push({path:'/calendar', query: { eventId : `${event.id}`, editMode: true}});
        },
        openEventDetails(id) {
            this.$router.push({path:'/calendar', query: { eventId : `${id}`}});
        },
        async getGroupEvents() {
            const response = await this.$api.getGroupEvents(this.groupId);

            if(response.success) {
                this.groupEvents = response.events;
            }
        }
    },
    computed: {
        ...mapStores(useUserStore),
    }
}
</script>

<style lang="scss" scoped>
div {
    @include side-component;
    padding: 10px;
    text-align: center;
    position: relative;

    overflow: hidden;
    &.expandable {
      transition: max-height 0.3s;
      max-height: 62px;
      min-width: 318px;
      &.expand {
        max-height: 400px;
        @include scrollbars(5px, var(--light), var(--medium), 10px);
        overflow: auto;

        .event {
          overflow: auto;
          max-height: 340px;
        }
      }
    }

    svg {
      position: absolute;
      top: 22px;
      right: 22px;
      transition: transform 0.3s;
      &:not(.expand) {
        transform: rotateZ(90deg);
      }
    }

    & .events-list {
        display: flex;
        flex-direction: column;
        
        .event {
            display: grid;
            position: relative;
            grid-template-columns: 40px auto;
            grid-template-rows: auto auto;
            gap: 0px 0px;
            grid-template-areas:
                "image title"
                "image date";

            text-align: left;
            margin-bottom: 10px;

            .icon-wrapper {
                position: absolute;
                width: auto !important;
                min-width: 0 !important;
                background: transparent;
                right: 0;
                z-index: 9999;
            }


            img {
                grid-area: image;
                width: 25px;
            }


            h4 {
                @include dot-overflow;
                grid-area: title;
                margin: 0;
                color: var(--text);
            }
            h5 {
                grid-area: date;
                margin: 0;
                color: var(--text-darker);
            }

            &:hover {
                cursor: pointer;
                border-radius: 5px;
                // padding: 4px 4px;
                background-color: rgba(grey, $alpha: .2);
            }
        }
    }

    h3 {
      @include side-component-title;
      &:hover {
        background: var(--background);
      }
      padding: 15px;
      margin-top: -5px;
      margin-left: -5px;
      margin-right: -5px;
      border-radius: 10px;
      cursor: pointer;
      transition: background 0.2s;
    }
}
</style>
