<template>
  <div class="GroupTab__container">
    <div class="tmp">
      <div class="tmp2" ref="scrollDiv">
        <GroupTab
          class="GroupTab"
          v-for="tab in reverseTabs"
          :key="tab.id"
          :tab="tab"
          :moderator="moderator"
          :creator="creator"
          :numOfTabs="tabs.length"
          @clicked="editTabForm"
          @tabUpdated="tabUpdated"
          @deleteTab="deleteTab"
          @getWidth="getWidth"
        />
      </div>
      <div class="tmp3">
        <div class="addSubGroup" :class="[creator || isAdmin ? '' : 'hideEle']">
          <img
            src="@/assets/icons/navigation-arrow-previous.svg"
            class="imgBack"
            alt="goBackArrow"
            @click="slideLeft"
          />
          <div class="circle" 
            @click="showFormForSubGroup" 
            :class="{ hideIcon : iconState()}">
            <span class="plus">+</span>
          </div>
          <img
            src="@/assets/icons/navigation-arrow-next.svg"
            class="imgNext"
            alt="goNextArrow"
            @click="slideRight"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import GroupTab from "@/components/group/GroupTab"

export default {
  name: "GroupTabs",
  components: { GroupTab },
  props: {
    creator: Boolean,
    moderator: Boolean,
    tabs: Array,
  },
  data() {
    return {
      isAdmin: false,
      margin: 0,
      fullElWidth: 0
    }
  },
  mounted() {
    this.isAdmin = this.userStore.isAdmin
  },
  methods: {
    iconState() {
      if(this.creator || this.moderator) {
        return false
      }
      return true
    },
    slideRight() {
      if(this.margin > -(this.fullElWidth)) {
        this.margin -= 100
        this.$refs.scrollDiv.style.marginLeft = this.margin + "px"
      } else {
        return
      }
    },
    getWidth(tabWidth) {
      this.fullElWidth = tabWidth * this.tabs.length;
    },
    slideLeft() {
      if(this.margin < 0) {
        this.margin += 100
        this.$refs.scrollDiv.style.marginLeft = this.margin + "px"
      } else {
        return
      }
    },
    showFormForSubGroup() {
      this.$emit("clicked")
    },
    editTabForm(tabId) {
      this.$emit("tabFormEdit", tabId)
    },
    tabUpdated(val) {
      this.$emit("tabUpdated", val)
    },
    deleteTab(deletedTabId) {
      this.$emit("deleteTab", deletedTabId)
    },
  },
  computed: {
    ...mapStores(useUserStore),
    reverseTabs() {
      const getMain = this.tabs.slice(0,1);
      const getTabs = this.tabs.slice(1).reverse();
      const getReversedArray = getMain.concat(getTabs)
      return getReversedArray
    }
  }
}
</script>

<style lang="scss" scoped>
.GroupTab__container {
  display: flex;
  align-items: flex-end;
  border-bottom: 3px solid #fd6631;
  max-width: 100%;
  overflow-y: hidden;

  .tmp {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    &2 {
      display: flex;
      overflow: hidden;

      @include respond-to('small') {
        overflow: auto;
      }
    }

    &3 {
      margin-left: auto;
    }
  }

  .GroupTab {
    display: flex;
  }

  .addSubGroup {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 30px;
    height: 45px;
    border-radius: 15px 15px 0 0;
    background-color: var(--dark);

    @include respond-to('small') {
        padding: 0 10px;
    }

    .hideIcon {
      visibility: hidden;
    }

    .imgNext {
      width: 15px;
      margin-left: 5px;

      @include respond-to('small') {
        display: none;
      }
    }

    .imgBack {
      width: 15px;
      margin-right: 5px;
      
      @include respond-to('small') {
        display: none;
      }
    }

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-image: linear-gradient(
        120deg,
        rgb(253, 142, 0) 0%,
        rgb(253, 102, 49) 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: transform 0.2s;

      .plus {
        color: var(--dark);
        width: 30px;
        text-align: center;
        font-weight: bold;
        font-size: 2.5rem;
      }
    }

    .circle:active {
      transform: scale(1.2);
    }
  }
}

 .hideEle {
   @include respond-to("small") {
     display: none !important;
   }
  }
</style>
