<template>
  <div class="main">
    <Navbar class="navbar" />

    <div class="info" :style="{ backgroundImage: coverImg }" v-if="isReady">
      <div class="imageContainer">
        <img class="groupImage" :src="groupImg" />
      </div>
      <div class="groupData">
        <img
          v-if="isCreator"
          class="editButton"
          src="@/assets/icons/edit-2.svg"
          @click="edit"
        />
        <h1>
          {{ model.name }} <span class="tabName">{{ tabName }}</span>
        </h1>
        <p>{{ model.description }}</p>
        <div class="controls">
          <FollowButton
            v-if="!isCreator"
            :id="model.id"
            :isGroup="true"
            @refresh="getGroupInfo"
          />
        </div>
      </div>

      <img
        v-if="adminOnly"
        class="adminTab"
        src="@/assets/icons/admin-tab.svg"
      />
    </div>

    <div class="menu">
      <EventForm v-if="isCreator || isModerator" :id="this.model.id" :mainTabId="mainTabId" />
      <br />
      <MembersList
        :updatedMember="updatedMember"
        :membersList="model.members"
        :id="this.model.id"
        :creatorId="model.creator.id"
      />
      <br />
      <GroupArchive 
        :groupId="model.id"
        :groupCreator="isCreator" />
      <br />
      <GroupPermission 
        v-if="isCreator"
        :groupId="this.model.id"
        :creatorId="model.creator.id"
        :membersList="model.members"
        @updateMembersList="updateMembersList($event)" />
    </div>

    <!-- Displays Tabs - and you can navigate between them -->
    <GroupTabs
      class="groupTabs"
      :tabs="model.tabs"
      :creator="isCreator"
      :moderator="isModerator"
      @clicked="modalActiveHandler"
      @tabFormEdit="getTabState"
      @tabUpdated="tabUpdated"
      @deleteTab="deleteTab"
    />

    <router-view
      class="tabFeed"
      :adminOnly="adminOnly"
      :isMember="isMember"
      :moderator="isModerator"
      :creator="isCreator"
      :groupId="model.id"
      :tabView="currentTabView"
    ></router-view>

    <div v-if="showForm" class="modal" @click.self="showForm = false">
      <CreatTabForm
        class="subGroupForm"
        :moderator="isModerator"
        :getEditedTab="getEditedTab"
        @formClosed="showForm = false"
        @tabSubmited="tabSubmited"
      />
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Navbar from "@/components/Navbar"
import EventForm from "@/components/group/EventForm"
import FollowButton from "@/components/FollowButton"
import Events from "@/components/group/Events"
import PostForm from "@/components/post/PostForm"
import Post from "@/components/post/Post"
import PostExposed from "@/components/post/PostExposed"
import MembersList from "@/components/group/MembersList"
import GroupArchive from "@/components/group/GroupArchive"
import GroupTabs from "@/components/group/GroupTabs"
import CreatTabForm from "@/components/group/CreateTabForm"
import GroupPermission from "@/components/group/GroupPermission";

export default {
  name: "Group",
  components: {
    Navbar,
    EventForm,
    FollowButton,
    Events,
    PostForm,
    Post,
    PostExposed,
    MembersList,
    GroupArchive,
    GroupTabs,
    CreatTabForm,
    GroupPermission
  },
  data() {
    return {
      model: {
        id: parseInt(this.$route.params.id),
        name: "",
        description: "",
        creator: {
          id: 0,
          username: "",
        },
        posts: [],
        exposed: [],
        events: [],
        members: [],
        tabs: [],
        cover_img_url: "",
        profile_img_url: "",
      },
      mainTabId: null,
      currentTabView: 0,
      currentTabName: "",
      adminOnly: false,
      showForm: false,
      isModerator: false,
      isCreator: false,
      isMember: false,
      isReady: false,
      getEditedTab: -1,
      updatedMember: null // GroupPermission component can refresh members in members list when permissions are changed
    }
  },
  mounted() {
    this.getGroupInfo()
    this.getGroupTabs()
  },
  async created() {
    this.currentTabView = +this.$route.params.tabId
    this.getBasicTabData()
  },
  watch: {
    $route(to, from) {
      this.currentTabView = +this.$route.params.tabId
      this.getBasicTabData()
    },
  },
  computed: {
    ...mapStores(useUserStore),
    tabName() {
      return "~ " + this.currentTabName
    },
    coverImg() {
      let img = this.$formatter.formatCoverImg(this.model.cover_img_url)
      return img === false ? "none" : "url('" + img + "')"
    },
    groupImg() {
      let img = this.$formatter.formatGroupImg(this.model.profile_img_url)
      return img === false ? "none" : img
    },
  },
  methods: {
    updateMembersList(member) {
      this.updatedMember = member;
    },
    async getBasicTabData() {
      const response = await this.$api.getTab(
        this.currentTabView,
        this.userStore.accessToken
      )

      if (response.success) {
        this.currentTabName = response.tab.name
        this.adminOnly = !!response.tab.admin_only
      } else {
        // If user wants a tab that dosen't exists it redirects him to main
        const response2 = await this.$api.getTabs(this.model.id, this.userStore.accessToken)

        if (response2.success) {
          this.model.tabs = [...response2.tabs]
        }
        this.$router.push(
          `/group/${this.model.id}/tab/${this.model.tabs[0].id}`
        )
      }
    },
    modalActiveHandler() {
      this.showForm = true
    },
    getTabState(tabId) {
      this.getEditedTab = tabId
    },
    edit() {
      this.$router.push({ path: `/group/settings/${this.$route.params.id}` })
    },
    async tabSubmited() {
      this.getGroupTabs()
    },
    deleteTab(deletedTabId) {
      for (let tab of this.model.tabs) {
        if (tab.id === deletedTabId) {
          const deletedTabIndex = this.model.tabs.indexOf(tab)
          this.model.tabs.splice(deletedTabIndex, 1)
          this.$router.push(
            `/group/${this.model.id}/tab/${this.model.tabs[0].id}`
          )
        }
      }
    },
    tabUpdated(editedTab) {
      for (let tab of this.model.tabs) {
        if (tab.id === editedTab.tabId) {
          tab.name = editedTab.tabName
          this.currentTabName = editedTab.tabName
        }
      }
    },
    getTabId() {
      return this.model.tabs[0]
    },
    updatePostData(updateData) {
      let post = this.model.posts.find((obj) => obj.id === updateData.postId)
      post.text = updateData.updatedText
    },
    async refreshPosts() {
      const response = await this.$api.getGroupInfo(this.model.id, null, this.userStore.accessToken)
      if (response.success === true) {
        this.model.posts = response.posts
        this.model.exposed = response.exposed
      }
    },
    async getGroupInfo() {
      const response = await this.$api.getGroupInfo(this.model.id, null, this.userStore.accessToken)
      if (response.success === true) {
        this.model.name = response.info.name
        this.model.description = response.info.description
        this.model.creator.id = response.info.id_creator
        this.model.creator.username = response.info.username
        this.model.cover_img_url = response.info.img_url
        this.model.profile_img_url = response.info.profile_img_url

        this.model.posts = response.posts
        this.model.exposed = response.exposed
        this.model.events = response.events
        this.model.members = response.members

        this.isCreator = response.info.id_creator === this.userStore.user.id;
        this.isModerator = this.model.members.some((member) => this.userStore.user.id === member.id && member.permission === 'moderator')
        this.isMember = !!this.model.members.find((member) => member.id === this.userStore.user.id)

        this.isReady = true
      }
    },
    async getGroupTabs() {
      const response = await this.$api.getTabs(this.model.id, this.userStore.accessToken)

      if (response.success) {
        this.model.tabs = [...response.tabs]
        this.mainTabId = this.model.tabs[0].id
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 0px 20px;
  grid-template-areas:
    "navbar navbar navbar"
    "info info events"
    "groupTabs groupTabs groupTabs"
    "menu tabFeed tabFeed";

  @include respond-to("medium") {
    display: grid;
    grid-template-columns: 0.6fr 3fr;
    grid-template-rows: auto auto;
    gap: 0px 20px;
    grid-template-areas:
      "navbar navbar"
      "info info"
      "groupTabs groupTabs"
      "menu tabFeed";
  }

  @include respond-to("small") {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0px 20px;
    grid-template-areas:
      "navbar"
      "info"
      "groupTabs"
      "tabFeed";
  }

  .groupTabs {
    grid-area: groupTabs;
    margin: 0 25px 20px 25px;
  }

  .tabFeed {
    grid-area: tabFeed;
  }

  .exposed {
    h4 {
      color: var(--text);
      padding: 10px 20px; /*20px;*/
      background: var(--background-darker);
      margin: 10px 20px;
      border-radius: 15px;

      display: flex;
      justify-content: space-between;
    }
  }

  .navbar {
    grid-area: navbar;
  }

  .info {
    grid-area: info;
    padding: 30px;
    margin-bottom: 20px;
    padding-left: 180px;
    position: relative;

    border-radius: 0 0 15px 0;

    background-color: var(--dark);
    background-position: center center;
    background-size: cover;
    text-shadow: 1px 1px darken(white, 80%);

    .groupImage {
      @include profile-img(120px);
      @include box-shadow($color: rgba(0, 0, 0, 0.2));
      border: 4px solid var(--dark);
      display: block;

      position: absolute;
      left: 30px;

      @include respond-to("small") {
        width: 140px;
        height: 140px;
        position: relative;
        margin-top: 20px;
      }
    }

    @include respond-to("small") {
      padding: 0 0 0 10px;
      width: 97vw;
    }

    h1 {
      word-break: break-all;
      color: var(--text);

      .tabName {
        color: #fd8e00;
        font-size: 1.8rem;
        letter-spacing: 1px;
      }
    }
    h3 {
      color: var(--medium);
      float: right;
    }
    p {
      color: var(--text);
    }

    .imageContainer {
      display: block;
    }

    .groupData {
      display: block;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .editButton {
      grid-area: edit;
      @extend .pointer;
      @extend .noselect;
      @extend .nodrag;

      @include respond-to("small") {
        margin-top: 10px;
      }

      @include gradient;
      border-radius: 10px;
      padding: 5px;

      &:hover {
        @include popup(1.1);
      }
    }

    .adminTab {
      @extend .noselect;
      @extend .nodrag;

      position: absolute;
      right: 20px;
      bottom: 10px;
      width: 40px;
      height: 40px;

      @include respond-to("small") {
        margin-top: 10px;
        top: 0;
        bottom: auto;
      }
    }


    .controls {
      display: flex;
      align-items: center;
      z-index: 1;

      button {
        @include button($gradient: true);
        margin-left: 20px;
      }
    }
    .creator {
      background: transparent;
    }
  }
  .posts {
    grid-area: posts;
    padding: 0 10%;

    @include respond-to("medium") {
      width: 50vw;
      padding: 0;
    }

    @include respond-to("small") {
      grid-area: 4;
      width: 100vw;
      padding: 0;
    }

    h5 {
      color: white;
      padding: 20px;
    }
    h2 {
      color: var(--text);
      text-align: center;
      margin: 50px;
    }
  }
  .posts::v-deep {
    article {
      margin-left: 20px;
      margin-right: 20px;
    }
    .container {
      form {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .menu {
    grid-area: menu;
    margin-left: 20px;

    @include respond-to("small") {
      display: none;
    }
  }
  .events {
    position: fixed;
    top: 20%;
    right: 20px;
    width: 15%;

    @include respond-to("medium") {
      display: block;
      position: revert;
    }

    @include respond-to("small") {
      display: none;
    }
  }

  .modal {
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100%;
    background-color: rgba(24, 25, 31, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .subGroupForm {
      position: fixed;
      width: 600px;
      height: auto;
      max-width: 80%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .menu {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 20px;
  }
}
</style>
