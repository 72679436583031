<template>
  <form @submit.prevent="handleSubmit">
    <img
      class="closeButton"
      src="@/assets/icons/close-button.svg"
      @click="closeForm"
    />
    <h3>Create a new tab</h3>

    <input
      type="text"
      v-model="model.name"
      placeholder="Tab name"
      minlength="1"
      required
    />
    <div class="errorContainer" v-if="error.length > 0">
      <span class="error">{{ error }}</span>
    </div>
    <div class="switcherContainer">
      <Switcher id="premisionChecker" label="Admin only tab" v-model="model.adminOnly" />
    </div>
    <input type="submit" value="Create Tab" />
  </form>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Switcher from "@/components/Switcher";

export default {
  name: "CreateTabForm",
  components: { Switcher },
  props: {
    moderator: Boolean
  },
  data() {
    return {
      model: {
        name: "",
        adminOnly: false
      },
      error: "",
    }
  },
  mounted() {},
  methods: {
    async handleSubmit() {
      if (this.model.name.length > 15) {
        this.error =
          "Name for the tag is too long. Please enter a string of characters that has between 1 and 15 letters."
      } else {
        let response = await this.$api.insertTab(
          this.getGroupId(),
          this.model.name,
          false,
          this.model.adminOnly ? 'adminOnly' : 'all',
          this.userStore.accessToken
        )

        if (response.success) {
          this.resetForm()
          this.$emit("tabSubmited")
        } else {
          this.error = response.error.message
        }
      }
    },
    closeForm() {
      this.$emit("formClosed")
    },
    getGroupId() {
      return this.$route.params.id
    },
    resetForm() {
      this.closeForm()
      this.model.name = ""
    },
  },
  computed: {
    ...mapStores(useUserStore),
  }
}
</script>

<style lang="scss" scoped>
form {
  @include side-component;
  @include box-shadow;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  width: 600px;
  min-height: 300px;
  height: auto;
  text-align: center;
  background-color: var(--dark);
  padding: 25px;

  @include respond-to("small") {
    width: 95vw;
  }

  .closeButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: absolute;
    top: 10px;
    left: 10px;

    &:hover {
      @include popup();
    }
  }

  h3 {
    @include side-component-title;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .switch-outer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1em;
  }

  .errorContainer {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    .error {
      color: var(--orange);
      font-weight: bold;
    }
  }

  .switcherContainer {
    width: 85%;
    margin: 0 auto;
    text-align: left;
  }

  input {
    @include text-input;

    width: 80%;
    padding: 7px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: none;

    display: block;
  }
  input[type="submit"] {
    @extend .pointer;

    width: 60%;
    border: none;
    background-color: var(--orange);

    &:hover {
      @include popup(1.02);
    }
  }
}
</style>
