<template>
    <div class="group-permission expandable" :class="{'expand':expand}">
        <header @click="expand = !expand" class="group-permission--header">
            <h3>Group admins</h3>
            <b-icon-chevron-down :class="{'expand':expand}">
            </b-icon-chevron-down>
        </header>
        <div class="group-permission--list">
            <div class="group-permission--list--member"
                v-for="member in members"
                :key="member.id">
                <img :src="$formatter.formatProfileImg(member.img_url)">
                <span>{{ member.username }}</span>
                <Switcher 
                    class="group-permission--list--member--switcher"
                    :checked="member.permission === 'moderator'"
                    @input="onUpdatePremission($event, member)" />
            </div>
        </div>
    </div>
</template>

<script>
    //Store
    import { mapStores } from "pinia";
    import { useUserStore } from "@/stores/UserStore";
    // Components
    import { BIconChevronDown } from "bootstrap-vue";
    import Switcher from "../Switcher.vue";

    export default {
        name: 'GroupPermission',
        components: { BIconChevronDown, Switcher },
        props: {
            groupId: Number,
            creatorId: Number,
            membersList: Array,
        },
        data() {
			return {
                expand: false,
                members: []
			};
		},
        mounted() {
            this.members = this.membersList.filter(el => el.id !== this.creatorId)
        },
        methods: {
            async onUpdatePremission(state, member) {
                const response = await this.$api.updateUsersPermission(member.id, this.groupId, state, this.userStore.accessToken)
                if(response.success) {
                    member.permission = state ? 'moderator' : null
                    this.$emit('updateMembersList', member)
                }
            }
        },
        computed: {
            ...mapStores(useUserStore),
        },
    }
</script>

<style lang="scss" scoped>
    .group-permission {
        @include side-component;
		padding: 10px 5px;
		text-align: center;
        position: relative;

        overflow: hidden;

        &.expandable {
            transition: max-height 0.3s;
            max-height: 62px;
            min-width: 318px;
            &.expand {
                max-height: 400px;
                .group-permission--list {
                    overflow-y: auto;
                    max-height: 340px;
                }
            }
        }

        &--header {
            cursor: pointer;

            border-radius: 10px;
            transition: background 0.2s;

            svg {
                position: absolute;
                top: 22px;
                right: 22px;
                transition: transform 0.3s;
    
                cursor: pointer;
                &:not(.expand) {
                    transform: rotateZ(90deg);
                }
            }
            
            h3 {
                @include side-component-title;
                padding: 15px;
                margin-top: -5px;
            }

            &:hover {
                background: var(--background);
            }
        }

        &--list {
            padding: 10px 5px 0px 5px;

            &--member {
                border-radius: 10px;
                padding: 5px;
                @extend .pointer;
                &:hover {
                    background: var(--background);
                }

                margin: 5px 5px 5px 0;
                display: flex;
                align-items: center;

                img {
                    @include profile-img(40px);
                    margin-right: 5px;
                }
                span {
                    color: var(--light);
                    margin-left: 1em;
                }

                &--switcher {
                    margin-left: auto;
                    padding: 0;
                }
            }
        }
    }
    
</style>