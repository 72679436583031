<template>
	<div class="container">
		<h3 class="noselect">Upcoming events</h3>

		<ul v-if="found.events.length > 0">
			<li v-for="event in found.events" :key="event.id">
				<h4>{{ event.title }}</h4>
				<h5>{{ $formatter.formatDateTime(event.date_when, true) }}</h5>
			</li>
		</ul>
		<h4 v-else>There is no upcoming events.</h4>
	</div>
</template>

<script>
	export default {
		name: "Events",
		props: {
			events: {
				type: Array,
				default: []
			}
		},
    data() {
		  return {
        found: {
          events: []
        }
      }
    },
    mounted() {
		  let today = new Date();
		  this.found.events = this.events;
      /*this.found.events = this.events.filter(function (event) {
        return Date.parse(event.date_when) >= Date.parse(today.toISOString());
      });*/
    },
    methods: {
			sortEvents() {
				// Sort events by date


				// Group them by day

			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		@include side-component;

		padding: 10px;

		h3 {
			@include side-component-title;
			text-align: center;
		}

		ul {
      padding: 0;
      overflow: hidden;
      li {
        display: inline-block;
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid var(--background-light);
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        h4 {
          color: var(--light);
          margin: 0;
        }
        h5 {
          color: var(--medium);
          margin: 0;
        }
      }
		}
	}
</style>
