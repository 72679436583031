<template>
	<div class="expandable" v-bind:class="{'expand':expand}">
		<h3 @click="expand = !expand">Members</h3>
    <b-icon-chevron-down 
      @click="expand = !expand" :class="{'expand':expand}">
    </b-icon-chevron-down>
    <div class="members-list">
      <div class="member"
          v-for="member in membersList"
          :key="member.id"
          :class="{creator: isCreator(member.id)}"
          @click="redirect(member.username, member.id)">
        <img :src="$formatter.formatProfileImg(member.img_url)">
        <span>{{ member.username }}</span>
        <small v-if="isCreator(member.id)">Creator</small>
        <small v-if="member.permission === 'moderator'">Moderator</small>
      </div>
    </div>
	</div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import { BIconChevronDown } from "bootstrap-vue";

	export default {
		name: "MembersList",
    components: { BIconChevronDown },
		props: {
			id: Number,
      creatorId: Number,
      membersList: Array,
      updatedMember: Object,
			admin: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
        expand: false
			};
		},
		mounted() {
			// Put group creator at the start of the array
			let index = this.membersList.indexOf(x => x.id === this.creatorId);

			if (index > -1) {
				let creator = this.membersList[index];
				this.membersList.splice(index, 1);
				this.membersList.unshift(creator);
			}
		},
    watch: {
      updatedMember: {
        handler() {
          this.membersList.find(el => el.id === this.updatedMember.id).permission = this.updatedMember.permission
        },
        deep: true
      }
    },
    computed: {
      ...mapStores(useUserStore),
    },
		methods: {
			isCreator(id) {
				return parseInt(id) === this.creatorId;
			},
			async redirect(username, memberId) {
        const getHash = await this.$api.getHash(memberId, this.userStore.accessToken);
        const hash = getHash.hash.hash_email;
        this.$router.push({ path: `/user/${username}-${hash}`})
			}
		}
	}
</script>

<style lang="scss" scoped>
	div {
		@include side-component;
		padding: 10px;
		text-align: center;
    position: relative;

    overflow: hidden;
    &.expandable {
      transition: max-height 0.3s;
      max-height: 62px;
      min-width: 318px;
      &.expand {
        max-height: 400px;
        .members-list {
          overflow-y: auto;
          max-height: 340px;
        }
      }
    }

    svg {
      position: absolute;
      top: 22px;
      right: 22px;
      transition: transform 0.3s;
      &:not(.expand) {
        transform: rotateZ(90deg);
      }
    }

		h3 {
			@include side-component-title;
      &:hover {
        background: var(--background);
      }
      padding: 15px;
      margin-top: -5px;
      margin-left: -5px;
      margin-right: -5px;
      border-radius: 10px;
      cursor: pointer;
      transition: background 0.2s;
		}
		h4 {
			color: var(--orange);
		}
    div.member {
      @extend .pointer;
      &:hover {
        background: var(--background);
      }

      margin: 5px 5px 5px 0;
      display: flex;
      align-items: center;

      img {
        @include profile-img(40px);
        margin-right: 5px;
      }
      span {
        color: var(--light);
        margin-left: 1em;
      }
      small {
        margin-left: auto;
        color: var(--orange);
      }
    }

    h2 {
      margin-left: 10px;
      padding: 5px;
      color: var(--medium);
    }
	}
</style>
